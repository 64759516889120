import api from "./api";
import { api_client, api_group } from "@/configs/app";

class DeviceGroupService {
  createGroup(client, license, name, valid_days) {
    return api
      .post(`${api_client}/${client}${api_group}`, {
        name: name,
        license_id: Number(license),
        valid_days: Number(valid_days),
      })
      .then((response) => response.data);
  }

  updateGroup(client, license, group, name, valid_days) {
    return api
      .put(`${api_client}/${client}${api_group}/${group}`, {
        name: name,
        license_id: Number(license),
        valid_days: Number(valid_days),
      })
      .then((response) => response.data);
  }

  deleteGroup(client, group) {
    return api.delete(`${api_client}/${client}${api_group}/${group}`);
  }
}

export default new DeviceGroupService();
