<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col">
                <h3 class="card-title text-info text-gradient">Clients</h3>
                <p class="text-dark">Manage clients</p>
              </div>
              <div class="col-auto">
                <vsud-button
                  class="mt-2"
                  color="light"
                  variant="gradient"
                  data-bs-toggle="modal"
                  data-bs-target="#createClientModal"
                >
                  Create
                </vsud-button>
              </div>
            </div>
            <hr class="horizontal dark" />
          </div>

          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-2">
              <div class="ms-2 me-2">
                <vsud-alert v-if="messageOK" color="success">
                  {{ messageOK }}
                </vsud-alert>
                <vsud-alert v-if="messageError" color="danger">
                  {{ messageError }}
                </vsud-alert>
              </div>

              <table class="table align-items-center mb-0 table-hover">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Contact
                    </th>
                    <th
                      class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Created
                    </th>
                    <th
                      class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Updated
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <template v-for="row in clientsList" :key="row.id">
                    <ClientRow :clientInfo="row" :onReloadData="reloadInfo" />
                  </template>
                </tbody>
              </table>
            </div>

            <div class="pe-3 pb-2 row">
              <div class="col">
                <ul class="list-group">
                  <li class="pb-1 text-sm border-0 list-group-item">
                    <strong class="text-dark">Total:</strong> &nbsp;
                    {{ this.clientsCount }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="createClientModal">
      <ModalCreateClient :onClickCreate="onCreateClient" />
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import ClientRow from "@/components/rows/ClientRow.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import ModalCreateClient from "@/components/modal/ModalCreateClient.vue";

export default {
  name: "ClientsList",

  components: {
    VsudButton,
    ClientRow,
    VsudAlert,
    ModalCreateClient,
  },

  data() {
    return {
      messageOK: null,
      messageError: null,
    };
  },

  computed: {
    clientsInfo() {
      return this.$store.getters["client/clientsListInfo"];
    },
    clientsList() {
      return this.clientsInfo.items;
    },
    clientsCount() {
      return this.clientsInfo.total_items;
    },
  },

  methods: {
    reloadInfo(error) {
      if (error != null) {
        if (error == "Saved" || error == "Deleted") {
          this.messageOK = error;
        } else {
          this.messageError =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }

        setTimeout(() => {
          this.messageOK = "";
          this.messageError = "";
        }, 2500);
      }

      this.$store.dispatch("client/clients", 0).catch(() => this.reloadInfo());
    },

    onCreateClient(name, contact, email) {
      this.$store
        .dispatch("client/create", {
          name: name,
          contact: contact,
          email: email,
        })
        .then(() => this.reloadInfo())
        .catch(() => this.reloadInfo());
    },
  },

  beforeMount() {
    if (!this.$store.state.auth.status.loggedIn) {
      this.$router.push({ name: "Sign In" });
      return;
    }

    this.reloadInfo();
  },
};
</script>
