<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0">Client Information</h6>
        </div>
      </div>
    </div>

    <div v-if="clientInfo && clientInfo.info" class="p-3 card-body">
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Name:</strong> &nbsp;
          {{ clientInfo.name }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Contact Name:</strong> &nbsp;
          {{ clientInfo.info.contact_name }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Contact Email:</strong> &nbsp;
          <a :href="mailTo()">{{ clientInfo.info.contact_email }}</a>
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Created:</strong> &nbsp;
          {{ $format("datetime", clientInfo.created) }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Last Update:</strong> &nbsp;
          {{ $format("datetime", clientInfo.info.updated) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientInfoCard",

  computed: {
    clientInfo() {
      return this.$store.getters["client/clientInfo"];
    },
  },

  methods: {
    mailTo() {
      if (this.clientInfo == null) return "";
      return "mailto: " + this.clientInfo.info.contact_email;
    },
  },
};
</script>
