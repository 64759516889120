<template>
  <tr :class="rowClass">
    <td class="text-xs mb-0 text-truncate" :style="'white-space: normal'">
      {{ firmwareInfo.version }} ({{ Math.round(firmwareInfo.size / 1024) }} Kb)
    </td>

    <td class="text-xs">
      {{ $format("date", firmwareInfo.created) }}
      {{ $format("time", firmwareInfo.created) }}
    </td>

    <td
      class="text-xs d-flex justify-content-center align-items-center pe-0 ps-0"
    >
      <Datepicker
        v-model="picked"
        :minDate="new Date()"
        :maxDate="new Date().addDays(365)"
        @cleared="clickDelete"
        :disabled="this.inRequest"
        :highlightWeekDays="[0, 6]"
        preventMinMaxNavigation
      />
    </td>
  </tr>
</template>

<script>
export default {
  name: "SelectFirmwareRow",

  components: {},

  props: {
    firmwareInfo: {
      type: Object,
      default: null,
    },
  },

  computed: {
    groupInfo() {
      return this.$store.getters["deviceGroup/selectedGroup"];
    },
    availability() {
      return this.$store.getters["deviceGroup/groupFirmwareAvailability"](
        this.groupInfo,
        this.firmwareInfo
      );
    },

    rowClass() {
      return this.inRequest ? "table-info" : "";
    },
  },

  data() {
    return {
      available: null,
      picked: null,

      isLoaded: false,
      inRequest: false,
    };
  },

  watch: {
    groupInfo: {
      handler() {
        this.reloadInfo();
      },
      immediate: true,
    },

    picked: {
      handler(newVal) {
        if (!this.isLoaded || this.inRequest) {
          return;
        }

        if (newVal && !this.available) {
          this.inRequest = true;

          this.$store
            .dispatch("firmware/makeAvailable", {
              firmwareID: this.firmwareInfo.id,
              available: newVal,
            })
            .then((resp) => {
              this.available = resp.id;
              this.inRequest = false;
            })
            .catch((error) => {
              console.log(error);
              this.inRequest = false;
            });
        }

        if (newVal == null && this.available) {
          this.inRequest = true;

          this.$store
            .dispatch("firmware/makeUnAvailable", this.available)
            .then(() => {
              this.available = null;
              this.inRequest = false;
            })
            .catch((error) => {
              console.log(error);
              this.inRequest = false;
            });
        }
      },
      immediate: true,
    },
  },

  methods: {
    reloadInfo() {
      this.isLoaded = false;

      this.available = this.availability ? this.availability.id : null;
      this.picked = this.availability
        ? this.$format("toDate", this.availability.available_since)
        : null;

      this.isLoaded = true;
    },

    clickDelete() {
      if (!this.isLoaded || this.inRequest) {
        return;
      }
      this.picked = null;
    },
  },

  beforeMount() {
    this.reloadInfo();
  },
};
</script>
