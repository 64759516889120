<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Available Firmwares</h5>
        <p v-if="this.groupInfo" class="text-info text-sm ms-2 mb-0">
          {{ this.groupInfo.name }}
        </p>
      </div>

      <div class="modal-body girlLookAtThatBody">
        <div class="row">
          <div class="col mb-4 table-wrap-25">
            <table
              class="table align-items-center mb-0 table-hover table-striped"
            >
              <thead>
                <tr class="table-fit">
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Version
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Created
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Available
                  </th>
                </tr>
              </thead>

              <tbody v-if="this.firmwaresList && this.groupInfo">
                <template v-for="row in firmwaresList" :key="row.id">
                  <SelectFirmwareRow :firmwareInfo="row" />
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col" />

        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Close</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import SelectFirmwareRow from "@/components/rows/SelectFirmwareRow.vue";

export default {
  name: "ModalSelectFirmware",

  components: {
    VsudButton,
    SelectFirmwareRow,
  },

  data() {
    return {
      showMenu: false,
    };
  },

  computed: {
    firmwaresList() {
      return this.$store.getters["client/firmwaresInfo"];
    },
    groupInfo() {
      return this.$store.getters["deviceGroup/selectedGroup"];
    },
  },

  methods: {
    refreshModalInfo() {
      document.getElementById("versionInfo").value = "";
    },
  },

  mounted() {
    document
      .getElementById("selectFirmwareModal")
      .addEventListener("shown.bs.modal", this.refreshModalInfo);
  },
};
</script>
