<template>
  <tr @click="showClient">
    <td class="align-middle">
      <p class="text-xs font-weight-bold ms-2 mb-0">
        <i
          v-if="isExpired"
          class="fa fa-exclamation me-1 text-danger"
          title="Expired Devices Group"
        />
        {{ clientInfo.name }}
      </p>
    </td>

    <td class="align-middle">
      <p class="text-xs font-weight-bold mb-1">
        {{ clientInfo.info.contact_name }}
      </p>
      <p class="text-xs mb-0">{{ clientInfo.info.contact_email }}</p>
    </td>

    <td class="align-middle">
      <p class="text-end text-xs font-weight-bold mb-1">
        {{ $format("date", clientInfo.created) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0">
        {{ $format("time", clientInfo.created) }}
      </p>
    </td>

    <td class="align-middle">
      <p class="text-end text-xs font-weight-bold mb-1">
        {{ $format("date", clientInfo.info.updated) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0">
        {{ $format("time", clientInfo.info.updated) }}
      </p>
    </td>
  </tr>
</template>

<script>
export default {
  name: "ClientRow",

  props: {
    clientInfo: {
      type: Object,
      default: null,
    },

    onReloadData: {
      type: Function,
      default: () => null,
    },
  },

  computed: {
    isExpired() {
      if (!this.clientInfo) return false;
      if (!this.clientInfo.licenses) return false;

      const curDate = new Date();
      for (const license of this.clientInfo.licenses) {
        if (!license.device_groups) continue;

        for (const group of license.device_groups) {
          if (Date.parse(group.valid_till) < curDate) {
            return true;
          }
        }
      }

      return false;
    },
  },

  methods: {
    showClient() {
      if (!this.clientInfo) return;

      this.$router.push({
        name: "Client Info",
        params: { id: this.clientInfo.id },
      });
    },
  },
};
</script>
