<template>
  <tr
    :class="rowClass"
    @click="this.$store.dispatch('devices/select', this.deviceInfo)"
    data-bs-toggle="modal"
    data-bs-target="#deviceTestsInfoModal"
  >
    <td class="align-middle">
      <p class="text-xs font-weight-bold mb-0">
        {{ deviceInfo.device }}
      </p>
    </td>

    <td class="align-middle">
      <p class="text-end text-xs font-weight-bold mb-1">
        {{ $format("date", deviceInfo.created) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0">
        {{ $format("time", deviceInfo.created) }}
      </p>
    </td>

    <td class="align-middle">
      <p
        v-if="deviceInfo.activated"
        class="text-end text-xs font-weight-bold mb-1"
      >
        {{ $format("date", deviceInfo.activated) }}
      </p>
      <p v-else class="text-center text-xs font-weight-bold mb-1">---</p>

      <p
        v-if="deviceInfo.activated"
        class="text-end text-xs text-secondary mb-0"
      >
        {{ $format("time", deviceInfo.activated) }}
      </p>
      <p v-else class="text-center text-xs text-secondary mb-0">---</p>
    </td>

    <td class="text-center">
      <i
        class="cursor-pointer fa fa-trash text-danger"
        data-bs-toggle="modal"
        data-bs-target="#deleteDeviceModal"
        title="Delete/Remove"
      />
    </td>
  </tr>
</template>

<script>
export default {
  name: "DeviceRow",

  props: {
    deviceInfo: {
      type: Object,
      default: null,
    },
  },

  computed: {
    selectedDevice() {
      return this.$store.getters["devices/selectedDevice"];
    },
    rowClass() {
      return this.selectedDevice == this.deviceInfo ? "table-info" : "";
    },
  },
};
</script>
