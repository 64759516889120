<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">License Key Information</h5>
      </div>

      <div class="modal-body">
        <div class="card">
          <div v-if="licenseInfo" class="p-2 card-body">
            <ul class="list-group">
              <li
                class="pt-0 text-sm border-0 list-group-item p-0 pb-1 text-truncate"
              >
                <strong class="text-dark">Key:</strong> &nbsp;
                <i
                  class="cursor-pointer fa fa-copy me-1"
                  title="Copy URL"
                  @click="onCopyKey"
                />
                {{ licenseInfo.hash }}
              </li>
              <li
                class="text-sm border-0 list-group-item p-0 pb-1 text-truncate"
              >
                <strong class="text-dark">Info:</strong> &nbsp;
                {{ licenseInfo.key }}
              </li>
              <li class="text-sm border-0 list-group-item p-0 pb-1">
                <strong class="text-dark">Info Decoded:</strong> &nbsp;
                {{ this.decodedInfo }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Close</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ModalLicenseInfo",

  components: {
    VsudButton,
  },

  computed: {
    licenseInfo() {
      return this.$store.getters["license/selectedLicense"];
    },
    decodedInfo() {
      return this.$store.getters["license/licenseInfo"];
    },
  },

  methods: {
    onCopyKey() {
      navigator.clipboard.writeText(this.licenseInfo.hash);
    },
  },
};
</script>
