<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Tests Information</h5>
        <p v-if="this.deviceInfo" class="text-info text-sm ms-2 mb-0">
          {{ this.deviceInfo.device }}
        </p>
      </div>

      <div class="modal-body">
        <div v-if="testsList" class="p-2 card-body table-wrap-250">
          <table
            class="table align-items-center mb-0 table-hover table-striped table-sm"
          >
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Result
                </th>
                <th
                  class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Tested
                </th>
                <th
                  class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Added
                </th>
              </tr>
            </thead>

            <tbody v-if="testsList">
              <template v-for="row in testsList" :key="row.id">
                <TestRow :testInfo="row" />
              </template>
            </tbody>

            <tfoot>
              <ul v-if="testsList" class="list-group">
                <li class="pb-1 text-sm border-0 list-group-item">
                  <strong class="text-dark">Total:</strong>
                  {{ this.testsList.length }}
                </li>
              </ul>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col"></div>

        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Close</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import TestRow from "@/components/rows/TestRow.vue";

export default {
  name: "ModalTestsInfo",

  components: {
    VsudButton,
    TestRow,
  },

  computed: {
    deviceInfo() {
      return this.$store.getters["devices/selectedDevice"];
    },
    testsList() {
      return this.$store.getters["devices/testsList"];
    },
  },

  // mounted() {
  //   document
  //     .getElementById("deviceTestsInfoModal")
  //     .addEventListener("shown.bs.modal", this.refreshModalInfo);

  //   document
  //     .getElementById("deviceTestsInfoModal")
  //     .addEventListener("hidden.bs.modal", this.onHideModal);
  // },
};
</script>
