<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Firmware Information</h5>
      </div>

      <div class="modal-body">
        <div class="card">
          <div v-if="firmwareInfo" class="p-2 card-body">
            <ul class="list-group">
              <li class="pt-0 text-sm border-0 list-group-item p-0 pb-1">
                <strong class="text-dark">Version:</strong> &nbsp;
                {{ this.firmwareInfo.version }}
              </li>
              <li class="text-sm border-0 list-group-item p-0 pb-1">
                <strong class="text-dark">Created:</strong> &nbsp;
                {{ $format("time", firmwareInfo.created) }}
                {{ $format("date", firmwareInfo.created) }}
              </li>
              <li class="text-sm border-0 list-group-item p-0 pb-1">
                <strong class="text-dark">Size:</strong> &nbsp;
                {{ Math.round(firmwareInfo.size / 1024) }} Kb
              </li>
              <li
                class="text-sm border-0 list-group-item p-0 pb-1 text-truncate"
              >
                <strong class="text-dark">URL:</strong> &nbsp;
                <i
                  class="cursor-pointer fa fa-copy me-1"
                  title="Copy URL"
                  @click="onCopyFirmwareURL"
                />
                {{ firmwareInfo.url }}
              </li>
              <li class="text-sm border-0 list-group-item p-0 text-truncate">
                <strong class="text-dark">CRC:</strong> &nbsp;
                <i
                  class="cursor-pointer fa fa-copy me-0"
                  title="Copy CRC"
                  @click="onCopyCRC"
                />
                {{ firmwareInfo.crc }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="danger"
            variant="gradient"
            @click="onClickDelete"
            >Delete</vsud-button
          >
        </div>

        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Close</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ModalFirmwareInfo",

  components: {
    VsudButton,
  },

  computed: {
    clientInfo() {
      return this.$store.getters["client/clientInfo"];
    },
    firmwareInfo() {
      return this.$store.getters["firmware/selectedFirmware"];
    },
  },

  methods: {
    onClickDelete() {
      this.$store
        .dispatch("firmware/delete")
        .catch((error) => console.log(error));
    },

    refreshModalInfo() {
      this.isCopied = false;
    },

    onHideModal() {
      this.isCopied = false;
      this.$store.dispatch("firmware/select", null);
    },

    onCopyFirmwareURL() {
      navigator.clipboard.writeText(this.firmwareInfo.url);
    },
    onCopyCRC() {
      navigator.clipboard.writeText(this.firmwareInfo.crc);
    },
  },

  mounted() {
    document
      .getElementById("firmwareInfoModal")
      .addEventListener("shown.bs.modal", this.refreshModalInfo);

    document
      .getElementById("firmwareInfoModal")
      .addEventListener("hidden.bs.modal", this.onHideModal);
  },
};
</script>
