<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>

                <div class="card-body">
                  <vsud-alert v-if="message" color="danger">
                    {{ message }}
                  </vsud-alert>

                  <form role="form" class="text-start">
                    <label>Email</label>
                    <vsud-input
                      ref="inputEmail"
                      id="email"
                      type="email"
                      placeholder="Email"
                      name="email"
                      v-model="email"
                    />

                    <label>Password</label>
                    <vsud-input
                      ref="inputPass"
                      id="password"
                      type="password"
                      placeholder="Password"
                      autocomplete="current-password"
                      name="password"
                      v-model="pass"
                      v-on:keyup.enter="signInClick"
                    />
                  </form>

                  <div class="text-center">
                    <vsud-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="success"
                      full-width
                      :disabled="this.loading || !this.email || !this.pass"
                      @click="signInClick"
                      >Sign in
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/layout/PageLayout/Footer.vue";
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "@/components/VsudAlert.vue";

const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";

export default {
  name: "SignIn",

  components: {
    AppFooter,
    VsudInput,
    VsudButton,
    VsudAlert,
  },

  data() {
    return {
      loading: false,
      message: "",

      email: null,
      pass: null,
    };
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },

  beforeMount() {
    if (this.$store.state.auth.status.loggedIn) {
      this.$router.push({ name: "Dashboard" });
      return;
    }
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    resetData() {
      this.email = null;
      this.pass = null;
      this.loading = false;
    },

    signInClick() {
      if (!this.email || !this.pass) {
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("auth/login", { email: this.email, pass: this.pass })
        .then(
          () => {
            this.resetData();
            this.$router.push({ name: "Dashboard" });
          },
          (error) => {
            this.resetData();

            console.log(error);

            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setTimeout(() => (this.message = ""), 2500);
          }
        );
    },
  },
};
</script>
