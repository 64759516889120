<template>
  <div class="form-group">
    <label :for="id">
      <slot />
    </label>
    <textarea
      :id="id"
      class="form-control"
      :rows="rows"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "VsudTextarea",

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: String,
      default: "",
    },

    id: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: "Your text here...",
    },

    rows: {
      type: Number,
      default: 5,
    },
  },
};
</script>
