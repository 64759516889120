<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <h6 class="mb-0">Analytics</h6>
        </div>

        <div class="col" />
      </div>
    </div>

    <div class="p-2 card-body table-wrap-250">
      One day some kind analytics reports will appear here :)
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientAnalyticsListCard",
};
</script>

<style></style>
