import AuthService from "../services/auth.service";

const sessionInfo = JSON.parse(sessionStorage.getItem("session"));
const initialState = sessionInfo
  ? { status: { loggedIn: true }, sessionInfo }
  : { status: { loggedIn: false }, sessionInfo: null };

export const auth = {
  namespaced: true,

  state: initialState,

  actions: {
    login({ commit }, loginInfo) {
      return AuthService.login(loginInfo).then(
        (sessionInfo) => {
          commit("loginSuccess", sessionInfo);
          return Promise.resolve();
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },

    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
  },

  mutations: {
    loginSuccess(state, sessionInfo) {
      state.status.loggedIn = true;
      state.sessionInfo = sessionInfo;
    },

    loginFailure(state) {
      state.status.loggedIn = false;
      state.sessionInfo = null;
    },

    logout(state) {
      state.status.loggedIn = false;
      state.sessionInfo = null;
    },
  },
};
