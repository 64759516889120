<template>
  <tr
    :class="rowClass"
    @click="this.$store.dispatch('firmware/select', firmwareInfo)"
    data-bs-toggle="modal"
    data-bs-target="#firmwareInfoModal"
  >
    <td class="align-middle text-truncate" style="max-width: 20px">
      <p class="text-center text-xs mb-1">
        {{ firmwareInfo.version }}
      </p>
      <p class="text-center text-xs text-secondary mb-0">
        ({{ Math.round(firmwareInfo.size / 1024) }} Kb)
      </p>
    </td>

    <td class="align-middle text-truncate" style="max-width: 70px">
      <div class="row text-xs mb-1">
        <div class="col">
          <p class="text-start text-xs mb-1">
            {{ firmwareInfo.url }}
          </p>
        </div>
      </div>
      <p class="text-start text-xs text-secondary mb-0">
        CRC: {{ firmwareInfo.crc }}
      </p>
    </td>

    <td class="text-middle">
      <p class="text-end text-xs font-weight-bold mb-1">
        {{ $format("date", firmwareInfo.created) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0">
        {{ $format("time", firmwareInfo.created) }}
      </p>
    </td>
  </tr>
</template>

<script>
export default {
  name: "FirmwareRow",

  props: {
    firmwareInfo: {
      type: Object,
      default: null,
    },
  },

  computed: {
    selectedFirmware() {
      return this.$store.getters["firmware/selectedFirmware"];
    },
    rowClass() {
      return this.firmwareInfo == this.selectedFirmware ? "table-info" : "";
    },
  },
};
</script>
