<template>
  <tr :class="rowClass">
    <td class="align-middle" :style="'white-space: normal'">
      <p class="text-xs font-weight-bold mb-0">
        {{ testInfo.results }}
      </p>
    </td>

    <td class="align-middle">
      <p class="text-end text-xs font-weight-bold mb-1">
        {{ $format("date", testInfo.tested) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0">
        {{ $format("time", testInfo.tested) }}
      </p>
    </td>

    <td class="align-middle">
      <p class="text-end text-xs font-weight-bold mb-1">
        {{ $format("date", testInfo.created) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0">
        {{ $format("time", testInfo.created) }}
      </p>
    </td>
  </tr>
</template>

<script>
export default {
  name: "TestRow",

  props: {
    testInfo: {
      type: Object,
      default: null,
    },
  },

  computed: {
    rowClass() {
      if (this.testInfo.results) {
        try {
          const jsonObj = JSON.parse(this.testInfo.results);
          if (jsonObj.result != 0) return "table-danger";
        } catch (error) {
          return "";
        }
      }

      return "";
    },
  },
};
</script>
