<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create a New Client</h5>
      </div>

      <div class="modal-body">
        <vsud-input
          id="clientName"
          placeholder="Legal/Entity Name"
          name="text"
          type="text"
          v-model="clientName"
        />

        <vsud-input
          id="contactName"
          placeholder="Contact Name"
          name="text"
          type="text"
          v-model="contactName"
        />

        <vsud-input
          id="contactEmail"
          placeholder="Contact Email"
          name="email"
          type="email"
          v-model="contactEmail"
        />
      </div>

      <div class="modal-footer">
        <div class="col">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="success"
            variant="gradient"
            @click="
              onClickCreate(
                this.clientName,
                this.contactName,
                this.contactEmail
              )
            "
            >Create</vsud-button
          >
        </div>

        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Close</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ModalCreateClient",

  components: {
    VsudInput,
    VsudButton,
  },

  props: {
    onClickCreate: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      clientName: null,
      contactName: null,
      contactEmail: null,
    };
  },

  methods: {
    refreshModalInfo() {
      this.clientName = null;
      this.contactName = null;
      this.contactEmail = null;
    },
  },

  mounted() {
    document
      .getElementById("createClientModal")
      .addEventListener("shown.bs.modal", this.refreshModalInfo);
  },
};
</script>
