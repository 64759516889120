import api from "./api";
import {
  api_client,
  api_firmware,
  api_firmware_available,
} from "@/configs/app";

class FirmwareService {
  getClientFirmwares(client) {
    return api
      .get(`${api_client}/${client}${api_firmware}`)
      .then((response) => response.data.items);
  }

  createFirmware(client, versionInfo, file, hash) {
    const formData = new FormData();
    formData.append("data", file);
    formData.append("version", versionInfo);
    formData.append("file_name", file.name);
    formData.append("crc", hash);
    formData.append("size", file.size);

    return api
      .post(`${api_client}/${client}${api_firmware}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data.info);
  }

  deleteFirmware(client, firmware) {
    return api.delete(`${api_client}/${client}${api_firmware}/${firmware}`);
  }

  makeAvailable(client, firmware, deviceGroup, sinceDate) {
    return api
      .post(`${api_client}/${client}${api_firmware_available}`, {
        firmware_id: firmware,
        device_group_id: deviceGroup,
        available_since: sinceDate,
      })
      .then((response) => response.data);
  }

  makeUnAvailable(client, availableID) {
    return api.delete(
      `${api_client}/${client}${api_firmware_available}/${availableID}`
    );
  }

  getAvailableFirmware(client) {
    return api
      .get(`${api_client}/${client}${api_firmware_available}`)
      .then((response) => response.data);
  }
}

export default new FirmwareService();
