<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Device</h5>
      </div>

      <div class="modal-body">
        <vsud-input
          class="mb-0"
          id="deviceInfo"
          v-model="deviceInfo"
          placeholder="Device MAC/Unique ID"
          name="text"
          type="text"
        />

        <vsud-textarea
          id="testResults"
          v-model="testResults"
          :rows="7"
          placeholder='Test results as JSON string:
{
  "result":0,
  "trace_info": {
    ...
  }
}'
        />

        <p class="text-sm mb-1">Test date:</p>
        <Datepicker
          v-model="testsDate"
          :maxDate="new Date()"
          preventMinMaxNavigation
        />
      </div>

      <div class="modal-footer">
        <div class="col">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="success"
            variant="gradient"
            @click="onClickCreate"
            >Add</vsud-button
          >
        </div>

        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Close</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudTextarea from "@/components/VsudTextarea.vue";
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ModalCreateDevice",

  components: {
    VsudInput,
    VsudTextarea,
    VsudButton,
  },

  data() {
    return {
      deviceInfo: null,
      testResults: null,
      testsDate: new Date(),
    };
  },

  methods: {
    refreshModalInfo() {
      this.deviceInfo = null;
      this.testResults = null; // {"result":0,"trace_info": {}}
      this.testsDate = new Date();
    },

    onClickCreate() {
      this.$store
        .dispatch("devices/create", {
          device: this.deviceInfo,
          tests: this.testResults,
          testsDate: this.$format("toJSON", this.testsDate),
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    document
      .getElementById("createDeviceModal")
      .addEventListener("shown.bs.modal", this.refreshModalInfo);
  },
};
</script>
