import api from "./api";
import { base_url, api_info } from "@/configs/app";

class ApiInfoService {
  info() {
    return api
      .get(api_info, { baseURL: base_url })
      .then((response) => response.data);
  }
}

export default new ApiInfoService();
