<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create a New License</h5>
      </div>

      <div class="modal-body">
        <vsud-input
          id="licenseInfo"
          v-model="licenseInfo"
          placeholder="Info to store (app name, bundle id, etc)"
          name="text"
          type="text"
        />
      </div>

      <div class="modal-footer">
        <div class="col">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="success"
            variant="gradient"
            @click="onClickCreate"
            >Create</vsud-button
          >
        </div>

        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Close</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ModalCreateLicense",

  components: {
    VsudInput,
    VsudButton,
  },

  data() {
    return {
      licenseInfo: null,
    };
  },

  methods: {
    refreshModalInfo() {
      this.licenseInfo = null;
    },

    onClickCreate() {
      this.$store
        .dispatch("license/create", this.licenseInfo)
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    document
      .getElementById("createLicenseModal")
      .addEventListener("shown.bs.modal", this.refreshModalInfo);
  },
};
</script>
