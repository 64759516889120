import api from "./api";
import { api_client, api_group, api_device } from "@/configs/app";

class DeviceService {
  getTestsList(client, group, device, page) {
    return api
      .get(
        `${api_client}/${client}${api_group}/${group}${api_device}/${device}/tests`,
        { page: page }
      )
      .then((response) => response.data);
  }

  getDevicesList(client, group, page) {
    return api
      .get(`${api_client}/${client}${api_group}/${group}${api_device}`, {
        page: page,
      })
      .then((response) => response.data);
  }

  createDevice(client, group, device, tests, testsDate) {
    return api
      .post(`${api_client}/${client}${api_group}/${group}`, {
        device: device,
        test_result: tests,
        test_date: testsDate,
      })
      .then((response) => response.data);
  }

  uploadCSVFile(client, group, formData) {
    return api
      .post(`${api_client}/${client}${api_group}/${group}/csv`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => response.data);
  }

  deleteDevice(client, group, device) {
    return api
      .delete(
        `${api_client}/${client}${api_group}/${group}${api_device}/${device}`
      )
      .then((response) => response.data);
  }
}

export default new DeviceService();
