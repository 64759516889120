import api from "./api";
import { api_client } from "@/configs/app";

class ClientService {
  getClientsList(page) {
    return api
      .get(api_client, {
        page: Number(page),
      })
      .then((response) => response.data);
  }

  getClientInfo(id) {
    return api.get(`${api_client}/${id}`).then((response) => response.data);
  }

  createClient(name, contact, email) {
    return api
      .post(api_client, {
        name: name,
        contact_name: contact,
        contact_email: email,
      })
      .then((response) => response.data);
  }

  deleteClient(id) {
    return api.delete(`${api_client}/${id}`);
  }
}

export default new ClientService();
