import ClientService from "../services/client.service";
import FirmwareService from "@/services/firmware.service";

export const client = {
  namespaced: true,

  state() {
    return {
      clientsInfo: {
        items: [],
        total_items: 0,
      },

      client: {},
      firmwares: [],
      licenses: [],
      availableFirmwares: [],
    };
  },

  getters: {
    clientsListInfo: (state) => state.clientsInfo,

    clientInfo: (state) => state.client,
    firmwaresInfo: (state) => state.firmwares,
    licensesInfo: (state) => state.licenses,
    availableFirmwaresInfo: (state) => state.availableFirmwares,
  },

  mutations: {
    setClient(state, info) {
      state.client = info ? info : {};
      state.firmwares = info && info.firmwares ? info.firmwares : [];
      state.licenses = info && info.licenses ? info.licenses : [];
      state.availableFirmwares =
        info && info.available_firmwares ? info.available_firmwares : [];
    },

    setClientsInfo(state, info) {
      state.clientsInfo = info ? info : {};
    },

    setAvailableFirmwares(state, info) {
      state.availableFirmwares = info ? info : [];
    },
  },

  actions: {
    clients({ commit }, page) {
      return ClientService.getClientsList(page).then(
        (response) => {
          commit("setClientsInfo", response);
          return Promise.resolve();
        },
        (error) => {
          commit("setClientsInfo", null);
          return Promise.reject(error);
        }
      );
    },

    reset({ dispatch, commit }) {
      dispatch("license/select", null, { root: true });
      commit("setClient", null);
    },

    get({ dispatch, commit }, clientID) {
      dispatch("license/select", null, { root: true });

      return ClientService.getClientInfo(clientID).then(
        (response) => {
          commit("setClient", response);
          return Promise.resolve();
        },
        (error) => {
          commit("setClient", null);
          return Promise.reject(error);
        }
      );
    },

    create({ dispatch }, payload = {}) {
      dispatch("reset", null);

      return ClientService.createClient(
        payload.name,
        payload.contact,
        payload.email
      ).then(
        () => {
          return Promise.resolve();
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    delete({ commit }, clientID) {
      return ClientService.deleteClient(clientID).then(
        () => {
          commit("setClient", null);
          return Promise.resolve();
        },
        (error) => {
          commit("setClient", null);
          return Promise.reject(error);
        }
      );
    },

    updateAvailableFirmwares({ getters, commit }) {
      const client = getters["clientInfo"];

      return FirmwareService.getAvailableFirmware(client.id).then(
        (info) => {
          commit("setAvailableFirmwares", info);
          return Promise.resolve();
        },
        (error) => {
          commit("setAvailableFirmwares", null);
          return Promise.reject(error);
        }
      );
    },
  },
};
