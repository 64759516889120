import FirmwareService from "@/services/firmware.service";
import CryptoJS from "crypto-js";

export const firmware = {
  namespaced: true,

  state() {
    return {
      firmware: null,
    };
  },

  getters: {
    selectedFirmware: (state) => state.firmware,

    getFirmwareInfo:
      (state, getters, rootState, rootGetters) => (availability) => {
        if (!availability) {
          return null;
        }

        const firmwares = rootGetters["client/firmwaresInfo"];
        if (!firmwares) {
          return null;
        }

        return firmwares.find((val) => {
          return val.id == availability.firmware_id;
        });
      },
  },

  mutations: {
    setFirmware(state, firmware) {
      state.firmware = firmware;
    },
  },

  actions: {
    select({ commit }, firmware) {
      commit("setFirmware", firmware);
    },

    create({ dispatch, rootGetters }, payload = {}) {
      const client = rootGetters["client/clientInfo"];

      const reader = new FileReader();

      reader.onloadend = function (evt) {
        if (evt.target.readyState != FileReader.DONE) {
          return;
        }

        var wordArray = CryptoJS.lib.WordArray.create(evt.target.result);
        var hash = CryptoJS.SHA256(wordArray);

        FirmwareService.createFirmware(
          client.id,
          payload.versionInfo,
          payload.file,
          hash.toString()
        ).then(
          () => {
            dispatch("client/get", client.id, { root: true });
            return Promise.resolve();
          },
          (error) => {
            dispatch("client/get", client.id, { root: true });
            return Promise.reject(error);
          }
        );
      };

      reader.readAsArrayBuffer(payload.file.slice(0, payload.file.size));
    },

    delete({ dispatch, commit, getters, rootGetters }) {
      const firmware = getters["selectedFirmware"];
      const client = rootGetters["client/clientInfo"];

      return FirmwareService.deleteFirmware(client.id, firmware.id).then(
        () => {
          commit("setFirmware", firmware);
          dispatch("client/get", client.id, { root: true });
          return Promise.resolve();
        },
        (error) => {
          dispatch("client/get", client.id, { root: true });
          return Promise.reject(error);
        }
      );
    },

    makeAvailable({ dispatch, rootGetters }, payload = {}) {
      const client = rootGetters["client/clientInfo"];
      const group = rootGetters["deviceGroup/selectedGroup"];

      return FirmwareService.makeAvailable(
        client.id,
        payload.firmwareID,
        group.id,
        payload.available
      ).then(
        (resp) => {
          dispatch("client/updateAvailableFirmwares", null, { root: true });
          return Promise.resolve(resp);
        },
        (error) => {
          dispatch("client/updateAvailableFirmwares", null, { root: true });
          return Promise.reject(error);
        }
      );
    },

    makeUnAvailable({ dispatch, rootGetters }, available) {
      const client = rootGetters["client/clientInfo"];

      return FirmwareService.makeUnAvailable(client.id, available).then(
        () => {
          dispatch("client/updateAvailableFirmwares", null, { root: true });
          return Promise.resolve();
        },
        (error) => {
          dispatch("client/updateAvailableFirmwares", null, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
};
