<template>
  <div v-if="this.userInfo != null" class="py-4 container-fluid">
    <div class="card bg-gradient-default">
      <div class="card-body">
        <h3 class="card-title text-info text-gradient">Info</h3>
        <blockquote class="blockquote text-white mb-0">
          <p class="text-dark ms-3">{{ this.userInfo }}</p>
        </blockquote>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h6 v-if="this.cmsInfo != null" class="mt-4">
          CMS version:
          <small class="text-muted">{{ this.cmsInfo.version }}</small> | Build:
          <small class="text-muted">{{ this.cmsInfo.build }}</small>
        </h6>
      </div>
      <div class="col">
        <h6 v-if="this.apiInfo != null" class="text-end mt-4">
          API version:
          <small class="text-muted">{{ this.apiInfo.version }}</small> | Build:
          <small class="text-muted">{{ this.apiInfo.build }}</small> | Uptime:
          <small class="text-muted">{{ this.apiInfo.uptime }}</small>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import ApiInfoService from "@/services/api-info.service";
import { version_info } from "@/configs/version_info";

export default {
  name: "dashboard-default",

  data() {
    return {
      apiInfo: null,
      cmsInfo: version_info,
      userInfo: this.$store.state.auth.sessionInfo.user,
    };
  },

  methods: {
    reloadInfo() {
      ApiInfoService.info()
        .then((info) => {
          this.apiInfo = info;
        })
        .catch((error) => {
          console.log(error);
          this.apiInfo = null;
        });
    },
  },

  beforeMount() {
    if (!this.$store.state.auth.status.loggedIn) {
      this.$router.push({ name: "Sign In" });
      return;
    }

    this.reloadInfo();
  },
};
</script>
