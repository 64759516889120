export const base_url = process.env.VUE_APP_API_BASE_URL;

export const api_login = process.env.VUE_APP_AUTH_LOGIN;
export const api_info = process.env.VUE_APP_INFO;

export const api_client = process.env.VUE_APP_ADMIN_CLIENT;
export const api_license = process.env.VUE_APP_ADMIN_LICENSE;

export const api_firmware = process.env.VUE_APP_ADMIN_FIRMWARE;
export const api_firmware_available = process.env.VUE_APP_ADMIN_FIRMWARE_AVAILABLE;

export const api_group = process.env.VUE_APP_ADMIN_DEVICE_GROUP;

export const api_device = process.env.VUE_APP_ADMIN_DEVICE;
export const api_device_tests = process.env.VUE_APP_ADMIN_TESTS;
