import api from "./api";
import { Buffer } from 'buffer';
import { base_url, api_login } from "@/configs/app";

class AuthService {
  login(info) {
    const token = Buffer.from(`${info.email}:${info.pass}`, "utf8").toString(
      "base64"
    );
    return api
      .get(api_login, {
        baseURL: base_url,
        headers: { Authorization: `Basic ${token}` },
      })
      .then((response) => {
        if (response.data) {
          sessionStorage.setItem("session", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    sessionStorage.removeItem("session");
  }
}

export default new AuthService();
