<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <h6 class="mb-0">Devices List</h6>
        </div>

        <div class="col" />

        <div class="col-auto">
          <vsud-button
            class="ms-2"
            color="light"
            variant="gradient"
            data-bs-toggle="modal"
            data-bs-target="#createDeviceModal"
          >
            <i class="fas fa-desktop me-1" />
            Add Device
          </vsud-button>

          <vsud-button
            class="ms-2"
            color="light"
            variant="gradient"
            @click="$refs.csvFile.click()"
          >
            <i class="fas fa-file-alt me-1" />
            Import from CSV
            <input
              type="file"
              ref="csvFile"
              accept="text/csv"
              style="display: none"
              @change="uploadCSVFile"
            />
          </vsud-button>
        </div>
      </div>
    </div>

    <div v-if="devicesList" class="p-2 card-body table-wrap-250">
      <table
        class="table align-items-center mb-0 table-hover table-striped table-sm"
      >
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Device
            </th>
            <th
              class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Created
            </th>
            <th
              class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Activated
            </th>

            <th class="table-fit" />
          </tr>
        </thead>

        <tbody v-if="devicesList">
          <template v-for="row in devicesList" :key="row.id">
            <DeviceRow :deviceInfo="row" />
          </template>
        </tbody>

        <tfoot>
          <ul class="list-group">
            <li class="pb-1 text-sm border-0 list-group-item">
              <strong class="text-dark">Total:</strong>
              {{ this.devicesCount }}
            </li>
          </ul>
        </tfoot>
      </table>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="createDeviceModal">
      <ModalCreateDevice />
    </div>

    <div class="modal fade" id="deviceTestsInfoModal">
      <ModalTestsInfo />
    </div>

    <div class="modal fade" id="deleteDeviceModal">
      <ModalConfirmation :onConfirm="deleteDevice">
        Do you really want to <b>completely delete</b> this device?<br />
        Please note <b>all content</b> linked to this device
        <b>will be removed also</b>.
      </ModalConfirmation>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import ModalCreateDevice from "@/components/modal/ModalCreateDevice.vue";
import ModalConfirmation from "@/components/modal/ModalConfirmation.vue";
import ModalTestsInfo from "@/components/modal/ModalTestsInfo.vue";
import DeviceRow from "@/components/rows/DeviceRow.vue";

export default {
  name: "DevicesListCard",

  components: {
    VsudButton,
    DeviceRow,
    ModalCreateDevice,
    ModalConfirmation,
    ModalTestsInfo,
  },

  data() {
    return {
      showMenu: false,
    };
  },

  computed: {
    groupInfo() {
      return this.$store.getters["deviceGroup/selectedGroup"];
    },
    devicesList() {
      return this.$store.getters["devices/devicesList"];
    },
    devicesCount() {
      return this.$store.getters["devices/devicesCount"];
    },
  },

  methods: {
    loadDevicesInfo() {
      if (!this.groupInfo) return;
    },

    deleteDevice() {
      this.$store
        .dispatch("devices/delete")
        .catch((error) => console.log(error));
    },

    uploadCSVFile() {
      if (!this.$refs.csvFile.files) {
        return;
      }

      this.$store
        .dispatch("devices/createFromCSV", this.$refs.csvFile.files[0])
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style></style>
