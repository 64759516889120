<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <h6 class="mb-0">Firmwares</h6>
        </div>

        <div class="col" />

        <div class="col-auto">
          <vsud-button
            class="ms-4"
            color="light"
            size="xsm"
            variant="gradient"
            data-bs-toggle="modal"
            data-bs-target="#createFirmwareModal"
          >
            +
          </vsud-button>
        </div>
      </div>
    </div>

    <div v-if="clientInfo" class="p-2 card-body table-wrap-250">
      <table class="table align-items-center mb-0 table-hover table-striped">
        <thead>
          <tr>
            <th
              class="table-fit text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Version
            </th>
            <th
              class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7"
            >
              URL
            </th>
            <th
              class="table-fit text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Created
            </th>
          </tr>
        </thead>

        <tbody v-if="firmwares">
          <template v-for="row in firmwares" :key="row.id">
            <FirmwareRow :firmwareInfo="row" />
          </template>
        </tbody>

        <tfoot>
          <ul class="list-group">
            <li class="pb-1 text-sm border-0 list-group-item">
              <strong class="text-dark">Total:</strong>
              {{ this.firmwaresCount }}
            </li>
          </ul>
        </tfoot>
      </table>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="firmwareInfoModal">
      <ModalFirmwareInfo />
    </div>

    <div class="modal fade" id="createFirmwareModal">
      <ModalCreateFirmware />
    </div>
  </div>
</template>

<script>
import FirmwareRow from "@/components/rows/FirmwareRow.vue";

import ModalFirmwareInfo from "@/components/modal/ModalFirmwareInfo.vue";
import ModalCreateFirmware from "@/components/modal/ModalCreateFirmware.vue";

import setTooltip from "@/assets/js/tooltip.js";
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ClientFirmwaresListCard",

  components: {
    FirmwareRow,
    ModalFirmwareInfo,
    ModalCreateFirmware,
    VsudButton,
  },

  computed: {
    clientInfo() {
      return this.$store.getters["client/clientInfo"];
    },
    firmwares() {
      return this.$store.getters["client/firmwaresInfo"];
    },

    firmwaresCount() {
      if (!this.firmwares) return 0;
      return this.firmwares.length.toLocaleString();
    },
  },

  methods: {
    createFirmware(versionInfo, file) {
      this.$store
        .dispatch("firmware/create", { versionInfo: versionInfo, file: file })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    setTooltip();
  },
};
</script>

<style></style>
