import api from "./api";
import { api_client, api_license } from "@/configs/app";

class LicenseService {
  getClientLicenses(client) {
    return api
      .get(`${api_client}/${client}${api_license}`)
      .then((response) => response.data);
  }

  decodeLicense(client, license) {
    return api
      .get(`${api_client}/${client}${api_license}/${license}`)
      .then((response) => response.data.info);
  }

  createLicense(client, info) {
    return api
      .post(`${api_client}/${client}${api_license}`, {
        info: info,
      })
      .then((response) => response.data.info);
  }

  deleteLicense(client, license) {
    return api.delete(`${api_client}/${client}${api_license}/${license}`);
  }
}

export default new LicenseService();
