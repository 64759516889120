import LicenseService from "@/services/license.service";

export const license = {
  namespaced: true,

  state() {
    return {
      license: null,
      decodedInfo: null,
    };
  },

  getters: {
    selectedLicense: (state) => state.license,
    licenseInfo: (state) => state.decodedInfo,
  },

  mutations: {
    setLicense(state, license) {
      state.license = license;
    },

    setLicenseInfo(state, info) {
      state.decodedInfo = info;
    },
  },

  actions: {
    select({ dispatch, commit, rootGetters }, license) {
      dispatch("deviceGroup/reset", null, { root: true });

      commit("setLicenseInfo", null);
      commit("setLicense", license);

      if (!license) {
        return;
      }

      const client = rootGetters["client/clientInfo"];
      if (!client) {
        return;
      }

      LicenseService.decodeLicense(client.id, license.id).then(
        (info) => {
          commit("setLicenseInfo", info);
          return Promise.resolve();
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    create({ dispatch, rootGetters }, info) {
      const client = rootGetters["client/clientInfo"];

      return LicenseService.createLicense(client.id, info).then(
        () => {
          dispatch("client/get", client.id, { root: true });
          return Promise.resolve();
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    delete({ dispatch, getters, rootGetters }) {
      const license = getters["selectedLicense"];
      const client = rootGetters["client/clientInfo"];

      return LicenseService.deleteLicense(client.id, license.id).then(
        () => {
          dispatch("client/get", client.id, { root: true });
          return Promise.resolve();
        },
        (error) => {
          dispatch("client/get", client.id, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
};
