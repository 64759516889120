<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Upload New Firmware</h5>
        <p class="modal-title text-danger">All fields mandatory!</p>
      </div>

      <div class="modal-body">
        <vsud-input
          class="mb-0"
          id="versionInfo"
          placeholder="Version number (1.0.0)"
          name="text"
          type="text"
          v-model="versionInfo"
          :error="!this.isVersionOK"
          :success="this.isVersionOK"
          isRequired
        />

        <p class="ms-2 mt-1 text-sm text-danger text-end">
          For the version number follow the
          <a href="https://semver.org/spec/v2.0.0.html" target="_blank"
            >Semantic Versioning</a
          >
          principles!
        </p>

        <vsud-input
          id="fileInfo"
          ref="inputFileInfo"
          placeholder="Firmaware file (zip)"
          type="file"
          accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
          isRequired
        />
      </div>

      <div class="modal-footer">
        <div class="col">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="success"
            variant="gradient"
            @click="clickCreate"
            >Create</vsud-button
          >
        </div>

        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Close</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";

const semver = require("semver");

export default {
  name: "ModalCreateFirmware",

  components: {
    VsudInput,
    VsudButton,
  },

  data() {
    return {
      versionInfo: null,
      isVersionOK: false,
    };
  },

  watch: {
    versionInfo: {
      handler(newValue) {
        this.isVersionOK =
          newValue && newValue.length && semver.valid(newValue) != null;
      },
      immediate: true,
    },
  },

  methods: {
    refreshModalInfo() {
      this.versionInfo = "";
      document.getElementById("fileInfo").value = "";
    },

    clickCreate() {
      if (!this.versionInfo.length) {
        return;
      }
      if (!semver.valid(this.versionInfo)) {
        return;
      }

      const fileInfo = this.$refs.inputFileInfo.$refs.fileInfo;
      if (!fileInfo.files || fileInfo.files.length == 0) {
        return;
      }

      const file = fileInfo.files[0];
      if (!file || file.type != "application/zip") {
        return;
      }

      this.$store
        .dispatch("firmware/create", {
          versionInfo: this.versionInfo,
          file: file,
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    document
      .getElementById("createFirmwareModal")
      .addEventListener("shown.bs.modal", this.refreshModalInfo);
  },
};
</script>
