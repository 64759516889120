import DeviceService from "@/services/device.service";

export const devices = {
  namespaced: true,

  state() {
    return {
      devices: [],
      devicesCount: 0,

      device: null,

      tests: [],
    };
  },

  getters: {
    devicesList: (state) => state.devices,
    devicesCount: (state) => state.devicesCount,

    selectedDevice: (state) => state.device,

    testsList: (state) => state.tests,
  },

  mutations: {
    setDevices(state, response) {
      state.devices = response && response.items ? response.items : [];
      state.devicesCount = response ? response.total_items : 0;
    },

    setDevice(state, device) {
      state.device = device;
    },

    setTests(state, response) {
      state.tests = response && response.items ? response.items : [];
      // state.testsCount = response ? response.total_items : 0
    },
  },

  actions: {
    select({ commit, rootGetters }, device) {
      commit("setDevice", device);
      commit("setTests", null);

      if (!device) {
        return Promise.resolve();
      }

      const group = rootGetters["deviceGroup/selectedGroup"];
      const client = rootGetters["client/clientInfo"];

      return DeviceService.getTestsList(client.id, group.id, device.id, 0).then(
        (response) => {
          commit("setTests", response);
          return Promise.resolve();
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        }
      );
    },

    reset({ commit }) {
      commit("setDevices", null);
      commit("setDevice", null);
    },

    get({ commit, rootGetters }) {
      const group = rootGetters["deviceGroup/selectedGroup"];
      const client = rootGetters["client/clientInfo"];

      return DeviceService.getDevicesList(client.id, group.id, 0).then(
        (response) => {
          commit("setDevices", response);
          return Promise.resolve();
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        }
      );
    },

    create({ dispatch, rootGetters }, payload = {}) {
      const group = rootGetters["deviceGroup/selectedGroup"];
      const client = rootGetters["client/clientInfo"];

      return DeviceService.createDevice(
        client.id,
        group.id,
        payload.device,
        payload.tests,
        payload.testsDate
      ).then(
        () => {
          dispatch("get");
          return Promise.resolve();
        },
        (error) => {
          dispatch("get");
          return Promise.reject(error);
        }
      );
    },

    createFromCSV({ dispatch, rootGetters }, file) {
      if (!file || file.type != "text/csv") {
        return Promise.reject("Wrong file format");
      }

      const formData = new FormData();
      formData.append("csv", file);

      const group = rootGetters["deviceGroup/selectedGroup"];
      const client = rootGetters["client/clientInfo"];

      return DeviceService.uploadCSVFile(client.id, group.id, formData).then(
        () => {
          dispatch("get");
          return Promise.resolve();
        },
        (error) => {
          dispatch("get");
          return Promise.reject(error);
        }
      );
    },

    delete({ dispatch, getters, rootGetters }) {
      const device = getters["selectedDevice"];
      const group = rootGetters["deviceGroup/selectedGroup"];
      const client = rootGetters["client/clientInfo"];

      return DeviceService.deleteDevice(client.id, group.id, device.id).then(
        () => {
          dispatch("get");
          return Promise.resolve();
        },
        (error) => {
          dispatch("get");
          return Promise.reject(error);
        }
      );
    },
  },
};
