import moment from "moment";

class DataFormatter {
  get(type, value) {
    const types = {
      date: (val) => {
        return moment(val).format("DD/MM/YYYY");
      },

      time: (val) => {
        return moment(val).format("HH:mm");
      },

      datetime: (val) => {
        return moment(val).format("HH:mm DD/MM/YYYY");
      },

      toDate: (val) => {
        if (!val) {
          return null;
        }
        return moment(val).toDate();
      },

      toJSON: (val) => {
        if (!val) {
          return null;
        }
        return moment(val).toDate().toJSON();
      },
    };

    return types[type](value);
  }

  install(app) {
    app.config.globalProperties.$format = (type, value) => {
      return this.get(type, value);
    };
  }
}

export default new DataFormatter();

Date.prototype.addDays = function (days) {
  return new Date(this.valueOf() + 864e5 * days);
};
