import DeviceGroupService from "@/services/devicegroup.service";

export const deviceGroup = {
  namespaced: true,

  state() {
    return {
      group: null,
      devices: [],
      devicesCount: 0,
    };
  },

  getters: {
    selectedGroup: (state) => state.group,

    groupAvailability: (state, getters, rootState, rootGetters) => (group) => {
      if (!group) {
        return null;
      }

      const availableFirmwares = rootGetters["client/availableFirmwaresInfo"];
      if (!availableFirmwares) {
        return null;
      }

      return availableFirmwares.find((val) => {
        return val.device_group_id == group.id;
      });
    },

    groupFirmwareAvailability:
      (state, getters, rootState, rootGetters) => (group, firmware) => {
        if (!group || !firmware) {
          return null;
        }

        const availableFirmwares = rootGetters["client/availableFirmwaresInfo"];
        if (!availableFirmwares) {
          return null;
        }

        return availableFirmwares.find((val) => {
          return (
            val.device_group_id == group.id && val.firmware_id == firmware.id
          );
        });
      },
  },

  mutations: {
    setGroup(state, group) {
      state.group = group;
      state.devices = [];
      state.devicesCount = 0;
    },
  },

  actions: {
    reset({ dispatch, commit }) {
      dispatch("devices/reset", null, { root: true });
      commit("setGroup", null);
    },

    select({ dispatch, commit }, group) {
      dispatch("devices/reset", null, { root: true });

      commit("setGroup", group);
      dispatch("devices/get", null, { root: true });
    },

    create({ dispatch, rootGetters }, payload = {}) {
      const client = rootGetters["client/clientInfo"];
      const license = rootGetters["license/selectedLicense"];

      return DeviceGroupService.createGroup(
        client.id,
        license.id,
        payload.name,
        payload.validDays
      ).then(
        () => {
          dispatch("client/get", client.id, { root: true });
          return Promise.resolve();
        },
        (error) => {
          dispatch("client/get", client.id, { root: true });
          return Promise.reject(error);
        }
      );
    },

    update({ dispatch, getters, rootGetters }, payload = {}) {
      const group = getters["selectedGroup"];
      const client = rootGetters["client/clientInfo"];
      const license = rootGetters["license/selectedLicense"];

      return DeviceGroupService.updateGroup(
        client.id,
        license.id,
        group.id,
        payload.name,
        payload.validDays
      ).then(
        () => {
          dispatch("client/get", client.id, { root: true });
          return Promise.resolve();
        },
        (error) => {
          dispatch("client/get", client.id, { root: true });
          return Promise.reject(error);
        }
      );
    },

    delete({ dispatch, getters, rootGetters }) {
      const group = getters["selectedGroup"];
      const client = rootGetters["client/clientInfo"];

      return DeviceGroupService.deleteGroup(client.id, group.id).then(
        () => {
          dispatch("client/get", client.id, { root: true });
          return Promise.resolve();
        },
        (error) => {
          dispatch("client/get", client.id, { root: true });
          return Promise.reject(error);
        }
      );
    },
  },
};
