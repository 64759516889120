<template>
  <tr
    :class="rowClass"
    @click="this.$store.dispatch('license/select', this.licenseInfo)"
  >
    <td class="align-middle">
      <p class="text-xs mb-0">
        <i
          class="cursor-pointer fa fa-info-circle me-1"
          data-bs-toggle="modal"
          data-bs-target="#licenseInfoModal"
          title="Show Key Info"
        />
        <i
          v-if="isExpired"
          class="fa fa-exclamation me-1 text-danger"
          title="Expired Devices Group"
        />
        {{ licenseInfo.hash }}
      </p>
    </td>

    <td class="align-middle">
      <p class="text-end text-xs font-weight-bold mb-1">
        {{ $format("date", licenseInfo.created) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0">
        {{ $format("time", licenseInfo.created) }}
      </p>
    </td>

    <td class="text-center">
      <i
        class="cursor-pointer fa fa-trash text-danger"
        data-bs-toggle="modal"
        data-bs-target="#deleteLicenseModal"
        title="Delete/Remove"
      />
    </td>
  </tr>
</template>

<script>
export default {
  name: "LicenseRow",

  props: {
    licenseInfo: {
      type: Object,
      default: null,
    },
  },

  computed: {
    selectedLicense() {
      return this.$store.getters["license/selectedLicense"];
    },
    highlight() {
      return (
        this.selectedLicense != null &&
        this.selectedLicense.id == this.licenseInfo.id
      );
    },
    rowClass() {
      return this.selectedLicense == this.licenseInfo ? "table-info" : "";
    },

    isExpired() {
      if (!this.licenseInfo) return false;
      if (!this.licenseInfo.device_groups) return false;

      const curDate = new Date();
      for (const group of this.licenseInfo.device_groups) {
        if (Date.parse(group.valid_till) < curDate) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>
