/**
=========================================================
* Vue Soft UI Dashboard - v2.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import dateFormatter from "./plugins/date-formatter";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";

import setupInterceptors from "./services/api-interceptors";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(dateFormatter);
appInstance.use(SoftUIDashboard);

setupInterceptors(store);

appInstance.component("Datepicker", Datepicker);

appInstance.mount("#app");
