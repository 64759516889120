<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <h6 class="mb-0">Software Licenses</h6>
        </div>

        <div class="col" />

        <div class="col-auto">
          <vsud-button
            class="ms-4"
            color="light"
            size="xsm"
            variant="gradient"
            data-bs-toggle="modal"
            data-bs-target="#createLicenseModal"
          >
            +
          </vsud-button>
        </div>
      </div>
    </div>

    <div v-if="clientInfo" class="p-2 card-body table-wrap-250">
      <table class="table align-items-center mb-0 table-hover table-striped">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Key
            </th>
            <th
              class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Created
            </th>

            <th class="table-fit" />
          </tr>
        </thead>

        <tbody v-if="licenses">
          <template v-for="row in licenses" :key="row.id">
            <LicenseRow :licenseInfo="row" />
          </template>
        </tbody>

        <tfoot>
          <ul class="list-group">
            <li class="pb-1 text-sm border-0 list-group-item">
              <strong class="text-dark">Total:</strong>
              {{ this.licensesCount }}
            </li>
          </ul>
        </tfoot>
      </table>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="licenseInfoModal">
      <ModalLicenseInfo />
    </div>

    <div class="modal fade" id="createLicenseModal">
      <ModalCreateLicense />
    </div>

    <div class="modal fade" id="deleteLicenseModal">
      <ModalConfirmation :onConfirm="deleteLicense">
        Do you really want to <b>completely delete</b> this license?
        <p v-if="this.licenseInfo" class="text-xs mt-2 mb-2">
          {{ this.licenseInfo.hash }}
        </p>
        Please note <b>all groups and devices</b> linked to this license
        <b>will be removed also</b>.
      </ModalConfirmation>
    </div>
  </div>
</template>

<script>
import LicenseRow from "@/components/rows/LicenseRow.vue";

import ModalLicenseInfo from "@/components/modal/ModalLicenseInfo.vue";
import ModalCreateLicense from "@/components/modal/ModalCreateLicense.vue";
import ModalConfirmation from "@/components/modal/ModalConfirmation.vue";

import setTooltip from "@/assets/js/tooltip.js";
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ClientLicensesListCard",

  components: {
    LicenseRow,
    ModalLicenseInfo,
    ModalCreateLicense,
    ModalConfirmation,
    VsudButton,
  },

  computed: {
    clientInfo() {
      return this.$store.getters["client/clientInfo"];
    },
    licenses() {
      return this.$store.getters["client/licensesInfo"];
    },
    licenseInfo() {
      return this.$store.getters["license/selectedLicense"];
    },

    licensesCount() {
      if (!this.licenses) return 0;
      return this.licenses.length.toLocaleString();
    },
  },

  methods: {
    deleteLicense() {
      this.$store
        .dispatch("license/delete")
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    setTooltip();
  },
};
</script>

<style></style>
