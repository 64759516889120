<template>
  <div class="container-fluid">
    <div
      class="mt-4 profile-page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved0.jpg') + ')',
        backgroundPositionY: '15%',
      }"
    >
      <vsud-button
        class="ms-4 mt-2"
        color="success"
        variant="gradient"
        @click="this.$router.go(-1)"
        >Back</vsud-button
      >
    </div>

    <div class="mx-4 card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto my-auto">
          <h5 v-if="clientInfo" class="mb-0">
            {{ clientInfo.name }}
          </h5>
        </div>

        <div class="col"></div>

        <div class="col-auto">
          <vsud-button
            class="ms-4"
            color="danger"
            variant="gradient"
            data-bs-toggle="modal"
            data-bs-target="#confirmationModal"
          >
            Delete?
          </vsud-button>
        </div>
      </div>
    </div>

    <div class="mt-4 container-fluid ps-0 pe-0">
      <div class="mt-2 row">
        <div class="col-12 col-md-6 col-xl-4 mt-md-0 mb-2">
          <ClientInfoCard v-if="clientInfo" />
        </div>

        <div class="col-12 col-md-6 col-xl-8 mt-md-0 mt-2 mb-2">
          <ClientAnalyticsListCard v-if="clientInfo" />
        </div>
      </div>
    </div>

    <div class="container-fluid ps-0 pe-0">
      <div class="mt-2 row">
        <div class="col-12 col-md-6 col-xl-4 mt-md-0 mb-2">
          <ClientFirmwaresListCard v-if="clientInfo" />
        </div>

        <div class="col-12 col-md-6 col-xl-8 mt-md-0 mt-2 mb-2">
          <ClientLicensesListCard v-if="clientInfo" />
        </div>
      </div>
    </div>

    <div v-if="this.selectedLicense" class="container-fluid ps-0 pe-0">
      <div class="mt-2">
        <DevicesGroupListCard v-if="clientInfo" />
      </div>
    </div>

    <div v-if="this.selectedGroup" class="container-fluid ps-0 pe-0">
      <div class="mt-3 mb-4">
        <DevicesListCard v-if="clientInfo" />
      </div>
    </div>

    <!-- Modal Confirmation -->
    <div class="modal fade" id="confirmationModal">
      <ModalConfirmation :onConfirm="deleteClient">
        Do you really want to <b>completely delete</b> this client?<br />
        Please note <b>all data</b> linked to this account
        <b>will be removed also</b>.
      </ModalConfirmation>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";

import ClientInfoCard from "@/components/cards/ClientInfoCard.vue";
import ClientAnalyticsListCard from "@/components/cards/ClientAnalyticsListCard.vue";
import ClientLicensesListCard from "@/components/cards/ClientLicensesListCard.vue";
import ClientFirmwaresListCard from "@/components/cards/ClientFirmwaresListCard.vue";
import DevicesGroupListCard from "@/components/cards/DevicesGroupListCard.vue";
import DevicesListCard from "@/components/cards/DevicesListCard.vue";

import ModalConfirmation from "@/components/modal/ModalConfirmation.vue";

export default {
  name: "ClientInfo",

  components: {
    VsudButton,
    ClientInfoCard,
    ClientAnalyticsListCard,
    ClientLicensesListCard,
    ClientFirmwaresListCard,
    DevicesGroupListCard,
    DevicesListCard,
    ModalConfirmation,
  },

  data() {
    return {
      showMenu: false,
    };
  },

  computed: {
    clientInfo() {
      return this.$store.getters["client/clientInfo"];
    },

    selectedGroup() {
      return this.$store.getters["deviceGroup/selectedGroup"];
    },

    selectedLicense() {
      return this.$store.getters["license/selectedLicense"];
    },
  },

  methods: {
    deleteClient() {
      this.$store
        .dispatch("client/delete", this.$route.params.id)
        .then(() => this.$router.go(-1))
        .catch((error) => console.log(error));
    },
  },

  beforeMount() {
    if (!this.$store.state.auth.status.loggedIn) {
      this.$router.push({ name: "Sign In" });
      return;
    }

    this.$store
      .dispatch("client/get", this.$route.params.id)
      .catch((error) => console.log(error));
  },

  beforeUnmount() {
    this.$store.dispatch("client/reset");
  },
};
</script>
