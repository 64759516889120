<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Edit Group of Devices</h5>
        <p v-if="this.groupInfo" class="text-info text-sm ms-2 mb-0">
          {{ this.groupInfo.name }}
        </p>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-auto mt-2 me-3">Name:</div>
          <div class="col">
            <vsud-input
              id="editGroupName"
              v-model="editGroupName"
              placeholder="Group name"
              name="text"
              type="text"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-auto mt-2">Valid Till:</div>
          <div class="col">
            <Datepicker
              v-model="picked"
              :minDate="new Date().addDays(1)"
              :maxDate="new Date().addDays(730)"
              preventMinMaxNavigation
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="success"
            variant="gradient"
            @click="clickSave"
          >
            Save
          </vsud-button>
        </div>

        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
          >
            Close
          </vsud-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ModalEditDeviceGroup",

  components: {
    VsudInput,
    VsudButton,
  },

  computed: {
    groupInfo() {
      return this.$store.getters["deviceGroup/selectedGroup"];
    },
  },

  data() {
    return {
      picked: new Date(),
      editGroupName: null,
    };
  },

  methods: {
    refreshModalInfo() {
      if (!this.groupInfo) return;

      this.picked = new Date(this.groupInfo.valid_till);
      this.editGroupName = this.groupInfo.name;
    },

    clickSave() {
      const valid_days = Math.ceil(
        (this.picked - new Date()) / (1000 * 3600 * 24)
      );

      this.$store
        .dispatch("deviceGroup/update", {
          name: this.editGroupName,
          validDays: valid_days,
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    document
      .getElementById("editDeviceGroupModal")
      .addEventListener("shown.bs.modal", this.refreshModalInfo);
  },
};
</script>
