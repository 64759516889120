<template>
  <tr
    :class="rowClass"
    @click="this.$store.dispatch('deviceGroup/select', groupInfo)"
  >
    <td class="align-middle">
      <p class="text-xs mb-0">
        {{ groupInfo.name }}
      </p>
    </td>

    <td class="align-middle cursor-pointer" @click="onClickFirmware(groupInfo)">
      <p
        v-if="this.firmwares.length"
        class="text-center font-weight-bold text-xs mb-1"
      >
        [ Select ... ]
      </p>
      <p v-else class="text-center font-weight-bold text-xs mb-1">[ - ]</p>
    </td>

    <td class="align-middle">
      <p class="text-end text-xs font-weight-bold mb-1">
        {{ $format("date", groupInfo.created) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0">
        {{ $format("time", groupInfo.created) }}
      </p>
    </td>

    <td class="align-middle">
      <p class="text-end text-xs font-weight-bold mb-1">
        {{ $format("date", groupInfo.updated) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0">
        {{ $format("time", groupInfo.updated) }}
      </p>
    </td>

    <td class="align-middle">
      <p class="text-end text-xs font-weight-bold mb-1" :class="getClasses()">
        {{ $format("date", groupInfo.valid_till) }}
      </p>
      <p class="text-end text-xs text-secondary mb-0" :class="getClasses()">
        {{ $format("time", groupInfo.valid_till) }}
      </p>
    </td>

    <td class="text-center">
      <i
        class="cursor-pointer fa fa-edit text-warning me-2"
        data-bs-toggle="modal"
        data-bs-target="#editDeviceGroupModal"
        title="Edit"
      />

      <i
        class="cursor-pointer fa fa-trash text-danger"
        data-bs-toggle="modal"
        data-bs-target="#deleteGroupModal"
        title="Delete/Remove"
      />
    </td>
  </tr>
</template>

<script>
export default {
  name: "DevicesGroupRow",

  props: {
    groupInfo: {
      type: Object,
      default: null,
    },

    onClickFirmware: {
      type: Function,
      required: true,
    },
  },

  computed: {
    firmwares() {
      return this.$store.getters["client/firmwaresInfo"];
    },
    selectedGroup() {
      return this.$store.getters["deviceGroup/selectedGroup"];
    },
    highlight() {
      return (
        this.selectedGroup != null && this.selectedGroup.id == this.groupInfo.id
      );
    },
    rowClass() {
      return this.selectedGroup == this.groupInfo ? "table-info" : "";
    },
  },

  methods: {
    getClasses() {
      return Date.parse(this.groupInfo.valid_till) > new Date()
        ? "text-success"
        : "text-danger";
    },
  },
};
</script>
