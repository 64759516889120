<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <h6 class="mb-0">Groups of Devices</h6>
        </div>

        <div class="col" />

        <div class="col-auto">
          <vsud-button
            class="ms-4"
            color="light"
            size="xsm"
            variant="gradient"
            data-bs-toggle="modal"
            data-bs-target="#createDeviceGroupModal"
          >
            +
          </vsud-button>
        </div>
      </div>
    </div>

    <div v-if="licenseInfo" class="p-2 card-body table-wrap-250">
      <table class="table align-items-center mb-0 table-hover table-striped">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Name
            </th>
            <th
              class="table-fit text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Firmware
            </th>
            <th
              class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Created
            </th>
            <th
              class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Updated
            </th>
            <th
              class="table-fit text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Valid Till
            </th>

            <th class="table-fit" />
          </tr>
        </thead>

        <tbody v-if="licenseInfo.device_groups">
          <template v-for="row in licenseInfo.device_groups" :key="row.id">
            <DevicesGroupRow
              :groupInfo="row"
              :onClickFirmware="onClickFirmware"
            />
          </template>
        </tbody>

        <tfoot>
          <ul class="list-group">
            <li class="pb-1 text-sm border-0 list-group-item">
              <strong class="text-dark">Total:</strong>
              {{ this.groupsCount }}
            </li>
          </ul>
        </tfoot>
      </table>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="createDeviceGroupModal">
      <ModalCreateDeviceGroup />
    </div>

    <div class="modal fade" id="editDeviceGroupModal">
      <ModalEditDeviceGroup />
    </div>

    <div class="modal fade" id="deleteGroupModal">
      <ModalConfirmation :onConfirm="deleteGroup">
        Do you really want to <b>completely delete</b> this group of devices?
        <p v-if="this.selectedGroup" class="text-xs mt-2 mb-2">
          {{ this.selectedGroup.name }}
        </p>
        Please note <b>all devices</b> linked to this group
        <b>will be removed also</b>.
      </ModalConfirmation>
    </div>

    <div class="modal fade" id="selectFirmwareModal">
      <ModalSelectFirmware />
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";

import VsudButton from "@/components/VsudButton.vue";

import DevicesGroupRow from "@/components/rows/DevicesGroupRow.vue";

import ModalCreateDeviceGroup from "@/components/modal/ModalCreateDeviceGroup.vue";
import ModalEditDeviceGroup from "@/components/modal/ModalEditDeviceGroup.vue";
import ModalConfirmation from "@/components/modal/ModalConfirmation.vue";
import ModalSelectFirmware from "@/components/modal/ModalSelectFirmware.vue";

export default {
  name: "DevicesGroupListCard",

  components: {
    VsudButton,
    DevicesGroupRow,
    ModalCreateDeviceGroup,
    ModalEditDeviceGroup,
    ModalConfirmation,
    ModalSelectFirmware,
  },

  computed: {
    licenseInfo() {
      return this.$store.getters["license/selectedLicense"];
    },
    selectedGroup() {
      return this.$store.getters["deviceGroup/selectedGroup"];
    },

    groupsCount() {
      if (!this.licenseInfo || !this.licenseInfo.device_groups) return 0;
      return this.licenseInfo.device_groups.length.toLocaleString();
    },
  },

  methods: {
    onClickFirmware(group) {
      const firmwares = this.$store.getters["client/firmwaresInfo"];
      if (!firmwares.length) {
        return;
      }

      this.$store.dispatch("deviceGroup/select", group);
      const myModal = new bootstrap.Modal(
        document.getElementById("selectFirmwareModal")
      );
      myModal.show();
    },

    deleteGroup() {
      this.$store
        .dispatch("deviceGroup/delete")
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style>
.table-wrap-250 {
  max-height: 250px;
  overflow-y: auto;
}

.table-fit {
  white-space: nowrap;
  width: 1px;
}
</style>
