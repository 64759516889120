<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create Group of Devices</h5>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-auto mt-2 me-3">Name:</div>
          <div class="col">
            <vsud-input
              id="groupName"
              v-model="groupName"
              placeholder="Group name"
              name="text"
              type="text"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-auto mt-2">Valid Till:</div>
          <div class="col">
            <Datepicker
              v-model="picked"
              :minDate="new Date().addDays(1)"
              :maxDate="new Date().addDays(730)"
              preventMinMaxNavigation
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="success"
            variant="gradient"
            @click="clickCreate"
          >
            Add
          </vsud-button>
        </div>

        <div class="col-auto">
          <vsud-button
            class="btn-secondary me-2"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Close</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudInput from "@/components/VsudInput.vue";
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ModalCreateDeviceGroup",

  components: {
    VsudInput,
    VsudButton,
  },

  data() {
    return {
      picked: new Date(),
      groupName: null,
    };
  },

  methods: {
    refreshModalInfo() {
      this.picked = new Date();
      this.groupName = null;
    },

    clickCreate() {
      const valid_days = Math.ceil(
        (this.picked - new Date()) / (1000 * 3600 * 24)
      );
      if (valid_days <= 0) {
        return;
      }

      this.$store
        .dispatch("deviceGroup/create", {
          name: this.groupName,
          validDays: valid_days,
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    document
      .getElementById("createDeviceGroupModal")
      .addEventListener("shown.bs.modal", this.refreshModalInfo);
  },
};
</script>
