<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmationLabel">Warning</h5>
      </div>

      <div class="modal-body">
        <slot />
      </div>

      <div class="modal-footer">
        <div class="col">
          <vsud-button
            class="btn-secondary"
            color="danger"
            variant="gradient"
            data-bs-dismiss="modal"
            @click="onConfirm"
            >Confirm</vsud-button
          >
        </div>
        <div class="col-auto">
          <vsud-button
            class="btn-secondary"
            data-bs-dismiss="modal"
            color="light"
            variant="gradient"
            >Cancel</vsud-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";

export default {
  name: "ModalConfirmation",

  components: {
    VsudButton,
  },

  props: {
    onConfirm: {
      type: Function,
      default: () => null,
    },
  },
};
</script>
