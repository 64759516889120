import api from "./api";
import { api_login } from "@/configs/app";

function authHeader() {
  let session = JSON.parse(sessionStorage.getItem("session"));

  if (session && session.token) {
    return "Bearer ".concat(session.token);
  }

  return "";
}

const setupInterceptors = (store) => {
  api.interceptors.request.use(
    (config) => {
      if (config.url !== api_login) {
        config.headers["Authorization"] = authHeader();
      }

      return config;
    },
    (error) => {
      console.log(store);

      return Promise.reject(error);
    }
  );
};

export default setupInterceptors;
