<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        id="navbar"
      >
        <div class="pe-md-3 d-flex align-items-center ms-md-auto" />

        <ul class="navbar-nav justify-content-end">
          <h3
            v-if="this.info != null"
            class="card-title text-info text-gradient me-2"
          >
            Hello, {{ this.info.user.info.first_name }}
          </h3>

          <li class="nav-item dropdown d-flex align-items-center pe-2 ps-3">
            <a
              href="#"
              class="p-0 nav-link"
              :class="[
                textWhite ? textWhite : 'text-body',
                showMenu ? 'show' : '',
              ]"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-user"></i>
            </a>

            <ul
              class="px-2 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-0">
                <a class="dropdown-item border-radius-md" @click="onSignOut">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="fas fa-sign-out-alt me-2"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm font-weight-normal">
                        <span class="font-weight-bold">Sign-out</span>
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "navbar",

  data() {
    return {
      showMenu: false,
      info: this.$store.state.auth.sessionInfo,
    };
  },

  props: ["minNav", "textWhite"],

  created() {
    this.minNav;
  },

  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    onSignOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "Sign In" });
    },
  },

  components: {
    Breadcrumbs,
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  updated() {
    window.addEventListener("scroll", () => {
      const navbar = document.getElementById("navbarBlur");

      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>
